import { defineStore } from 'pinia'
import api from '@/api'
import { RootAuthState } from "@/types/store";

export const useAuthStore = defineStore('auth', {
  state: (): RootAuthState => ({
    isAuth: localStorage.getItem('isAuth'),
    sessionID: () => localStorage.getItem('sessionID'),
  }),

  actions: {
    LOGIN(login: string, password: string) {
      return api.auth.login(login, password)
        .then(() => {
          localStorage.setItem('isAuth', 'true')
          this.isAuth = 'true'
        })
    },
    LOGOUT() {
      return api.auth.logout()
      .then(() => {
        localStorage.clear()
        location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
    }
  },

  getters: {
    IS_AUTH: (state) => state.isAuth === 'true',
    SESSION_ID: (state) => state.sessionID()
  }
})
