import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router';
import components from '@/plugins/components';
import 'element-plus/dist/index.css'
import '@/styles/main.scss';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import vClickOutside from '@/directives/clickOutside/index'

dayjs.extend(utc);

const app = createApp(App)

components.forEach((i) => {
  app.component(i.name, i.component)
})

app
  .use(vClickOutside)
  .use(createPinia())
  .use(router)
  .mount('#app');
