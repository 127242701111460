<template>
  <div class="container">
    Not found
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
