import { Nullable } from "@/types/helpers";

export enum Labels {
  PERSON = 'PERSON',
  CAR = 'CAR',
  MOTORBIKE = 'MOTORBIKE',
  BUS = 'BUS',
  TRUCK = 'TRUCK',
  BACKPACK = 'BACKPACK',
  HANDBAG = 'HANDBAG',
  SUITCASE = 'SUITCASE',
  smoke = 'smoke',
  fight = 'fight',
  spkvDefocus = 'spkv-defocus',
  spkvDestroyedFrame = 'spkv-destroyed_frame',
  spkvDirtSpeck = 'spkv-dirt_speck',
  spkvMonocromImage = 'spkv-monocrom_image',
  spkvObstacleInView ='spkv-obstacle_in_view',
  spkvWrongColoring = 'spkv-wrong_coloring',
  spkvWrongView = 'spkv-wrong_view',
  spkvObstacleInTree = 'spkv-obstacle_in_tree',
  spkvLookingAtWall = 'spkv-looking_at_wall',
  cluster = 'cluster',
}

export type Point = Readonly<{
  x: number
  y: number
}>

export type BBox = Readonly<{
  leftTop: Point
  rightBottom: Point
}>

export type Rule = Readonly<{
  id: string
  name: string
  points: Array<Point>
  direction: Nullable<string>
  cameraId: string
  createdAt: string
  labels: Array<Labels>
}>

export type Camera = {
  id: string
  name: string
  createdAt: string
  url: string
}

export type Event = Readonly<{
  id: string
  createdAt: string
  label: Labels
  imageLink: string
  rule: Rule
  detect: Array<Point>
  camera: Camera
}>
