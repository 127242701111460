import { Labels } from "@/types/events";

export const labelsRus = {
  [Labels.PERSON]: 'Человек',
  [Labels.CAR]: 'Машина',
  [Labels.MOTORBIKE]: 'Мотоцикл',
  [Labels.BUS]: 'Автобус',
  [Labels.TRUCK]: 'Грузовик',
  [Labels.BACKPACK]: 'Рюкзак',
  [Labels.HANDBAG]: 'Сумка',
  [Labels.SUITCASE]: 'Чемодан',
  [Labels.smoke]: 'Дым',
  [Labels.fight]: 'Драка',
  [Labels.spkvDefocus]: 'Расфокусировка',
  [Labels.spkvDestroyedFrame]: 'Разрушенный кадр',
  [Labels.spkvDirtSpeck]: 'Пятна или грязь',
  [Labels.spkvMonocromImage]: 'Монохромное изображение',
  [Labels.spkvObstacleInView]: 'Помеха обзору',
  [Labels.spkvWrongColoring]: 'Нарушена цветопередача',
  [Labels.spkvWrongView]: 'Неверная сцена обзора',
  [Labels.spkvObstacleInTree]: 'Помеха обзору в виде деревьев',
  [Labels.spkvLookingAtWall]: 'Камера направлена в стену',
  [Labels.cluster]: 'Скопление',
}
