import { defineStore } from 'pinia';
import { RootEventsStore } from "@/types/store";
import api from '@/api';
import addQuery from '@/helpers/addQuery';
import router from "@/router";
import { useEventsFiltersStore } from "@/store/eventsFilters";

export const useEventsStore = defineStore('events', {
  state: (): RootEventsStore => ({
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    events: [],
    isLoading: false
  }),
  actions: {
    async GET_EVENTS() {
      const eventsFiltersStore = useEventsFiltersStore()
      this.isLoading = true
      try {
        const res = await api.events.getEvents({
          limit: this.pageSize,
          offset: (this.currentPage - 1) * this.pageSize,
          orderBy: eventsFiltersStore.sortBy,
          order: eventsFiltersStore.sortOrder,
          cameraIds: eventsFiltersStore.camerasIds,
          labels: eventsFiltersStore.labels,
          ruleIds: eventsFiltersStore.rulesIds
        })
        this.events = res.data.events
        this.totalItems = res.data.total
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false
      }
    },
    INIT_PAGINATION() {
      this.currentPage = Number(router.currentRoute.value.query.page) || 1
      return addQuery('page', String(this.currentPage))
    }
  }
})
