<template>
  <div class="f-dropdown-item">
    <slot />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.f-dropdown-item {
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px 16px;
  width: 100%;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--col-5);
  }
}
</style>
