<template>
  <svg width="165" height="62" viewBox="0 0 165 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3144_9591)">
      <path d="M114.26 41.108C110.945 41.108 107.148 41.2045 103.639 41.039C101.644 40.9148 99.2642 39.728 97.8333 38.3481C97.0904 37.6443 96.5951 37.0372 96.0861 36.1954C102.277 36.1954 108.124 36.1954 114.315 36.1954C115.003 36.1954 115.32 35.6987 115.32 35.1881C115.32 32.7318 115.32 29.3372 115.32 26.8947C115.32 26.4532 115.072 25.8874 114.315 25.8874C114.123 25.8874 86.2903 25.8874 86.2903 25.8874C85.7263 25.8874 85.286 26.26 85.1622 26.7705C85.0934 27.0189 84.7219 28.8404 84.7219 31.1173C84.7219 32.2488 84.9008 34.1669 85.1622 35.2295C85.6575 37.6857 86.8407 40.763 88.9869 43.509C91.312 46.4483 93.9811 48.4767 97.5031 49.9257C100.365 51.0986 103.13 51.4298 106.074 51.4298C108.895 51.4298 111.509 51.4298 114.329 51.4298C114.824 51.4298 115.334 51.0572 115.334 50.4224C115.334 47.5798 115.334 44.1714 115.334 42.0601C115.32 41.5357 114.824 41.108 114.26 41.108Z" fill="#A51F37"/>
      <path d="M88.0514 20.8783H114.37C114.99 20.8783 115.375 20.3815 115.375 19.8709C115.375 19.112 115.375 12.3917 115.375 11.5776C115.375 11.0118 114.935 10.5702 114.37 10.5702C113.93 10.5702 109.184 10.5702 105.992 10.5702C103.048 10.5702 100.172 10.9428 97.4206 12.0743C93.9811 13.5233 91.1744 15.5793 88.8493 18.5462C88.4091 19.112 88.1889 19.3189 87.7074 20.0917C87.4735 20.4919 87.6524 20.8783 88.0514 20.8783Z" fill="#A51F37"/>
      <path d="M81.5025 27.5847C81.4613 27.3225 81.4062 27.0603 81.3512 26.7981C81.2274 26.2186 80.622 25.846 80.0717 25.846C77.7604 25.846 70.4273 25.846 69.2992 25.846C68.8589 25.846 68.4875 26.0944 68.2948 26.467C68.2948 26.467 65.6808 31.8763 64.0986 35.1605C63.906 35.5607 64.1674 36.154 64.6627 36.154C66.2311 36.154 67.7858 36.154 69.2854 36.154C69.6844 36.154 69.9183 36.4162 69.7257 36.7888C68.8864 38.3895 67.4694 39.6038 65.9009 40.418C62.7641 42.0601 58.8844 41.8669 55.8851 40.0454C53.6288 38.6655 52.0604 36.706 51.3725 34.1945C50.3131 30.2342 51.1799 26.6463 54.2479 23.6933C55.6925 22.3134 58.4991 21.0439 60.5078 20.9197C62.8329 20.7817 65.8184 20.8645 68.9277 20.8645C69.6431 20.8645 69.9183 20.6299 70.2898 19.8571C70.7438 18.9326 71.982 16.3659 73.0138 14.2408C73.7155 12.8057 74.1558 11.895 74.3346 11.5362C74.541 11.0808 74.2658 10.5426 73.7705 10.5426C71.5555 10.5426 61.3058 10.5426 61.3058 10.5426C49.9967 10.5426 40.8201 19.7605 40.8201 31.1173C40.8201 42.4741 49.9829 51.692 61.3058 51.692C61.3195 51.692 61.3195 51.692 61.3333 51.692C72.6286 51.6782 81.7777 42.4741 81.7777 31.1173C81.8052 29.9306 81.6952 28.7162 81.5025 27.5847Z" fill="#A51F37"/>
      <path d="M35.3581 10.5702C33.1431 10.5702 11.2678 10.5702 11.2678 10.5702C10.635 10.5702 10.2635 11.067 10.2635 11.5776C10.2635 11.9087 10.2635 19.4017 10.2635 19.8709C10.2635 20.4367 10.7037 20.8783 11.2678 20.8783C11.7081 20.8783 28.0939 20.8783 30.5153 20.8783C31.2307 20.8783 31.5196 20.5747 31.8773 19.8157C32.2075 19.1396 35.7433 11.9363 35.9084 11.5776C36.1423 11.1084 35.8672 10.5702 35.3581 10.5702Z" fill="#A51F37"/>
      <path d="M36.4037 46.0757C38.0685 43.8816 38.7288 41.2873 38.7288 38.6103C38.7288 35.9332 38.0685 33.339 36.4037 31.1449C33.7072 27.6123 30.1438 25.846 25.6312 25.846C20.637 25.846 16.1794 25.846 11.2816 25.846C10.7175 25.846 10.2773 26.3014 10.2773 26.8533C10.2773 29.7926 10.2773 32.3178 10.2773 35.1467C10.2773 35.6848 10.6762 36.154 11.2816 36.154C13.1252 36.154 20.926 36.154 25.6862 36.154C27.2959 36.154 28.2865 37.0786 28.314 38.6103C28.2865 40.142 27.2959 41.0665 25.6862 41.0665C20.926 41.0665 13.1252 41.0665 11.2816 41.0665C10.6762 41.0665 10.2773 41.5357 10.2773 42.0739C10.2773 44.9027 10.2773 47.4418 10.2773 50.3672C10.2773 50.9192 10.7175 51.3746 11.2816 51.3746C16.1794 51.3746 20.637 51.3746 25.6312 51.3746C30.1301 51.3746 33.7072 49.6221 36.4037 46.0757Z" fill="#A51F37"/>
      <path d="M145.477 11.8536C143.496 10.8324 140.511 10.2943 137.484 10.2943C134.457 10.2943 131.458 10.8186 129.491 11.8536C124.799 14.3098 120.369 18.4634 120.245 26.7015C120.245 29.7236 120.245 50.2983 120.245 50.2983C120.245 50.864 120.685 51.3056 121.249 51.3056C124.001 51.3056 126.739 51.3056 129.491 51.3056C129.986 51.3056 130.495 50.9882 130.495 50.2983C130.495 45.6479 130.495 33.0492 130.495 28.3299C130.495 25.5562 131.939 20.9197 137.443 20.9197C137.47 20.9197 137.484 20.9197 137.511 20.9197C137.539 20.9197 137.553 20.9197 137.58 20.9197C143.083 20.9197 144.528 25.5562 144.528 28.3299C144.528 33.0492 144.528 45.6341 144.528 50.2983C144.528 50.9882 145.023 51.3056 145.532 51.3056C148.284 51.3056 151.022 51.3056 153.773 51.3056C154.338 51.3056 154.778 50.864 154.778 50.2983C154.778 50.2983 154.778 29.7236 154.778 26.7015C154.613 18.4634 150.169 14.3098 145.477 11.8536Z" fill="#A51F37"/>
    </g>
    <defs>
      <clipPath id="clip0_3144_9591">
        <rect width="165" height="62" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
