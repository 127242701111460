import { defineStore } from 'pinia'
import { Notification } from '@/types/notifications';
import { RootNotificationState } from "@/types/store";

export const useNotificationsStore = defineStore('notifications', {
  state: (): RootNotificationState => ({
    notifications: []
  }),

  actions: {
    NOTIFY(notification: Omit<Notification, 'id'>, duration = 5) {
      const id = Date.now()
      this.notifications.push({ ...notification, id })
      if (duration) {
        setTimeout(() => {
          this.notifications = this.notifications.filter((i) => i.id !== id)
        }, duration * 1000)
      }
    },
    CLOSE_NOTIFICATION(id: number) {
      this.notifications = this.notifications.filter((i) => i.id !== id)
    }
  }
})
