import Button from '@/components/app-ui/Button.vue'
import Checkbox from '@/components/app-ui/Checkbox.vue'
// import ConfirmDialog from '@/components/app-ui/ConfirmDialog.vue'
import DatePicker from '@/components/app-ui/DatePicker.vue'
import Dialog from '@/components/app-ui/Dialog.vue'
import Notifications from '@/components/app-ui/Notifications.vue'
import Pagination from '@/components/app-ui/Pagination.vue'
// import Switch from '@/components/app-ui/Switch.vue'
import Loading from '@/components/app-ui/Loading.vue'
// import Tag from '@/components/app-ui/Tag.vue'
import TextInput from '@/components/app-ui/TextInput.vue'
import Description from '@/components/app-ui/Description.vue'
import Dropdown from '@/components/app-ui/Dropdown.vue'
import DropdownItem from '@/components/app-ui/DropdownItem.vue'
import Filters from '@/components/app-ui/Filters.vue'

export default [
  { name: 'f-button', component: Button },
  { name: 'f-checkbox', component: Checkbox },
  // { name: 'f-confirm-dialog', component: ConfirmDialog, },
  { name: 'f-date-picker', component: DatePicker, },
  { name: 'f-dialog', component: Dialog, },
  { name: 'f-notifications', component: Notifications, },
  // { name: 'f-switch', component: Switch, },
  { name: 'f-pagination', component: Pagination, },
  { name: 'f-loading', component: Loading, },
  // { name: 'f-tag', component: Tag, },
  { name: 'f-input', component: TextInput, },
  { name: 'f-description', component: Description, },
  { name: 'f-dropdown', component: Dropdown, },
  { name: 'f-dropdown-item', component: DropdownItem, },
  { name: 'f-filters', component: Filters, },
]
