import {
  createRouter,
  createWebHistory, NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import EventsView from '@/views/EventsView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import LoginView from "@/views/LoginView.vue";
import { useAuthStore } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/events',
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { title: "routes.login" },
    beforeEnter(to, from, next) {
      const authStore = useAuthStore()

      if (authStore.IS_AUTH) {
        next({ name: "events" })
      } else {
        next()
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
    meta: {
      loginRequired: true,
      title: 'Страница не найдена'
    }
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView,
    meta: {
      loginRequired: true,
      title: 'События'
    }
  },
];

function changeTitle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.meta.title) {
    document.title = `Видеоаналитика — ${to.meta.title}`
  }
  next()
}
function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const loginRequired = !!to.matched.find(({ meta }) => meta.loginRequired)
  const authStore = useAuthStore()

  if (!authStore.IS_AUTH && loginRequired) {
    next({ name: "login", query: { redirect: to.fullPath } })
  } else {
    next()
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard)
router.beforeEach(changeTitle)

export default router;
