import instance from "@/api/http";
import {
  EventsRequest,
  EventsResponse,
  CamerasResponse, RulesResponse
} from '@/types/api';

export default {
  getEvents(params: EventsRequest) {
    return instance.get<EventsResponse>(`events`, { params })
  },
  getCameras() {
    return instance.get<CamerasResponse>(`cameras`)
  },
  getRules() {
    return instance.get<RulesResponse>(`rules`)
  },
}
