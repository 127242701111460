import { defineStore } from "pinia";
import { RootEventsFiltersState } from '@/types/store';
import { OrderEnum } from "@/types/main";

export const useEventsFiltersStore = defineStore('eventsFilters', {
  state: (): RootEventsFiltersState => ({
    labels: [],
    camerasIds: [],
    rulesIds: [],
    sortBy: '',
    sortOrder: OrderEnum.desc
  }),

  actions: {
    SET_SORT_BY(sort: string) {
      this.sortBy = sort
    },
    TOGGLE_ORDER() {
      if (this.sortOrder === OrderEnum.desc) {
        this.sortOrder = OrderEnum.asc
      } else {
        this.sortOrder = OrderEnum.desc
      }
    }
  }
})
