<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_647_316598)">
      <path d="M13.3345 6.00254L7.33446 6.00206C6.59808 6.002 6.00108 6.59891 6.00102 7.33529L6.00054 13.3353C6.00048 14.0717 6.59739 14.6687 7.33377 14.6687L13.3338 14.6692C14.0701 14.6693 14.6671 14.0724 14.6672 13.336L14.6677 7.33598C14.6677 6.5996 14.0708 6.0026 13.3345 6.00254Z" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.33414 10.0018L2.66747 10.0017C2.31385 10.0017 1.97473 9.8612 1.7247 9.61113C1.47467 9.36107 1.33422 9.02192 1.33425 8.66829L1.33473 2.66829C1.33476 2.31467 1.47526 1.97554 1.72533 1.72552C1.9754 1.47549 2.31455 1.33504 2.66817 1.33507L8.66817 1.33555C9.02179 1.33558 9.36092 1.47608 9.61095 1.72615C9.86098 1.97622 10.0014 2.31537 10.0014 2.66899L10.0013 3.33566" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_647_316598">
        <rect width="16" height="16" transform="translate(0.00161743 0.00195312)"/>
      </clipPath>
    </defs>
  </svg>
</template>
