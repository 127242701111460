import { defineStore } from "pinia";
import { useSocket } from "@/api/websocket";
import { useEventsStore } from "@/store/events";
import { useEventsFiltersStore } from "@/store/eventsFilters";
import { Event } from "@/types/events";
import { useNotificationsStore } from "@/store/notifications";

export const useSocketStore = defineStore('socket', {
  state: (): {socket: null | WebSocket} => ({
    socket: null
  }),
  actions: {
    async INIT_SOCKET() {
      this.socket = useSocket()

      this.socket.onopen = () => {
        console.log('Successfully connected to the echo websocket server.')
      }
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        this.PROCESS_SOCKET_MESSAGE(data.data)
      }
      this.socket.onclose = () => {
        setTimeout(() => {
          this.INIT_SOCKET()
        }, 2000)
      }
    },
    PROCESS_SOCKET_MESSAGE(event: Event) {
      const eventStore = useEventsStore()
      const eventFiltersStore = useEventsFiltersStore()
      const notificationStore = useNotificationsStore()

      if (eventStore.events.find((i) => i.id === event.id)) {
        return
      }
      if (eventFiltersStore.labels.length > 0 && !eventFiltersStore.labels.includes(event.label)) {
        return
      }

      if (eventFiltersStore.rulesIds.length > 0 && !eventFiltersStore.rulesIds.includes(event.rule.id)) {
        return
      }

      if (eventFiltersStore.camerasIds.length > 0 && !eventFiltersStore.camerasIds.includes(event.rule.cameraId)) {
        return
      }

      if (eventStore.currentPage === 1) {
        eventStore.events.unshift(event)
        eventStore.totalItems += 1
      }

      notificationStore.NOTIFY({
        type: 'success',
        title: 'Новое событие',
        cameraName: event.camera.name,
        ruleName: event.rule.name,
        eventId: event.id,
        eventDate: event.createdAt
      }, 7)
    }
  }
})
