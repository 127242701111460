import axios from 'axios';

const instance = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'Content-type': 'application/json'
  },
  paramsSerializer: {
    indexes: null,
    encode: (param) => encodeURIComponent(param).replaceAll(" ", "%20"),
  }
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('isAuth')
      location.reload()
    }
    throw error
  }
)

export default instance
