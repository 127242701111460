import instance from "@/api/http";

export default {
  login(login: string, password: string) {
    const data = { login, password }
    return instance.post('login', data)
  },

  logout() {
    return instance.post('logout')
  }
}
