import { defineStore } from "pinia";
import router from "@/router";
import { RootDetailsEventStore } from "@/types/store";
import { useEventsStore } from "@/store/events";
import addQuery from '@/helpers/addQuery';
import deleteQuery from '@/helpers/deleteQuery';

export const useDetailsEventStore = defineStore('detailsEvent', {
  state: (): RootDetailsEventStore => ({
    modalActive: false,
    currentEvent: null,
    currentEventIndex: 0
  }),
  actions: {
    OPEN_MODAL(eventId: string) {
      const eventsStore = useEventsStore()
      eventsStore.events.forEach((value, index) => {
        if (eventId === value.id) {
          this.currentEventIndex = index
          this.currentEvent = value
        }
      })
      addQuery('eventId', eventId)
      this.modalActive = true
    },
    CLOSE_MODAL() {
      deleteQuery('eventId')
      this.modalActive = false
      this.currentEvent = null
    },
    INIT_MODAL() {
      const { eventId } = router.currentRoute.value.query
      if (typeof eventId === 'string') {
        this.OPEN_MODAL(eventId)
      }
    },
    NEXT_EVENT() {
      if (!this.modalActive) {
        return
      }
      const eventsStore = useEventsStore()

      if (this.currentEventIndex < eventsStore.events.length - 1) {
        this.currentEventIndex++
        this.OPEN_MODAL(eventsStore.events[this.currentEventIndex]?.id)
      }
    },
    PREV_EVENT() {
      if (!this.modalActive) {
        return
      }
      if (this.currentEventIndex > 0) {
        const eventsStore = useEventsStore()

        this.currentEventIndex--
        this.OPEN_MODAL(eventsStore.events[this.currentEventIndex]?.id)
      }
    }
  },
  getters: {

  }
})
